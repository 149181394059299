* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.header {
  /* border-bottom: 2px solid #0056b3; */
  padding: 10px 0;
  background-color: #ffffff; /* Dark blue background for a modern look */
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure the header is above other content */
}

.carousal-btn {
  padding: 20px 30px;
  margin: 50px;
  color: white;
  background-color: orange;
  border: none;
  border-radius: 8px;
}
.carousel-main {
  height: 50vh;
  position: relative;
}
.carousal-img {
  min-width: 100%;

  object-fit: cover;
  height: 50vh;
}
.carousal-text {
  color: #009dff;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 500;
}
.carousel-caption {
  transform: translateY(50%);
  bottom: 50% !important;
}

.carousal-form {
  position: absolute;
  top: 50vh;
  width: 100%;

  z-index: 100;
}
.know-more-button {
  padding: 8px 10px;
  background-color: #8cc63f;
  text-decoration: none;
  color: white;
  border-radius: 2px;
}
.know-more-button:hover {
  transition: 0.3s ease-in;
  background-color: white;
  color: #000000;
}
.brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 60px;
  margin-left: 20px;
  width: auto;
}
.logoText {
  color: rgb(0, 0, 0);
  font-family: "El Messiri", sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-left: 10px;
}
.footer-logoText {
  color: rgb(255, 255, 255);
  font-family: "El Messiri", sans-serif;
  display: flex;
  font-size: 46px;
  line-height: 46px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}
.contact-info {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.contact-link {
  color: #000000;

  margin-left: 15px;
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
}

.contact-link svg {
  margin-right: 5px;
  font-size: 1.2rem;
}

.contact-link:hover {
  color: #666666;
}

.nav-link {
  color: #000000 !important; /* Light text color */
  font-weight: 500;
  margin: 0 15px;
  padding: 5px 10px;
  border-radius: 3px;
  transition: color 0.3s ease, border-color 0.3s ease;
  padding: 5px !important;
  text-wrap: nowrap;
}

.nav-link:hover {
  color: #666666 !important; /* Highlight color on hover */
  border-color: #666666 !important; /* Border color on hover */
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%23f1f1f1' stroke-width='2' d='M5 7h20M5 15h20M5 23h20'/%3E%3C/svg%3E");
}

.navbar-collapse {
  justify-content: flex-end;
}
.navbar-toggler {
  background-color: #ffffff !important;
}
.nav-dropdown:hover .dropdown-menu {
  border-radius: 1px !important;
}

.dropdown-menu {
  background-color: #1e2a38;
  border: 2px solid #0056b3;
}

.dropdown-item {
  color: #f1f1f1;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-item:hover {
  background-color: #fff;
  color: #edf3f5;
}

.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  background-color: #25d366;
  border-radius: 50%;
  justify-content: center;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

.whatsapp-icon {
  color: white;
  font-size: 24px;
}

.photo-card {
  display: none;
  position: relative;
  height: 60vh;
}
.about-us-mission {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2rem;
}
.about-us-mission-sub {
  width: 70vw;
}
@media (max-width: 768px) {
  .contact-info {
    display: none;
  }

  .brand .logo {
    height: 60px;
  }
  .mobile-justify-right {
    justify-content: start !important;
  }
  .photo-card {
    height: 50vh;
  }
  .footer-res {
    flex-direction: column;
  }
  .footer-logoText {
    font-size: 30px;
    line-height: 33px;
  }
  .carousal-btn {
    font-size: 10px;
  }
}

/* src/components/Footer.css */
.footer {
  background-color: #1e2a38;
  color: #edf3f5;
  padding: 20px 0;
}

.footer-logo {
  max-width: 120px;
  height: auto;
}

.footer-links a,
.social-media a {
  color: #edf3f5;
  text-decoration: none;
}

.footer-links a:hover,
.social-media a:hover {
  text-decoration: underline;
}

.social-media .social-icon {
  margin-right: 8px;
}
.company-name {
  position: absolute;
  display: flex;
  top: 170px;
  left: 50%;
}
.comapany-h1 {
  font-size: 8rem;
  font-weight: 900;
  line-height: 0.7;

  color: #36454f; /* Text color */
  text-shadow: 1px 0 0 #36454f, -1px 0 0 #36454f, 0 1px 0 #36454f,
    0 -1px 0 #36454f, 1px 1px 0 #36454f, -1px -1px 0 #36454f, 1px -1px 0 #36454f,
    -1px 1px 0 #36454f;
}
.company-sub {
  font-size: 30px;
  font-weight: bolder;
  color: #36454f;
}
@media (max-width: 768px) {
  .footer-logo {
    max-width: 100px;
    margin-bottom: 15px;
  }
  .company-name {
    top: 280px;
    left: -15% !important;
  }
  .comapany-h1 {
    font-size: 3rem;
  }
  .company-sub {
    font-size: 20px;
  }
}
@media (max-width: 500px) {
  .company-name {
    left: -25% !important;
  }
}
@media (max-width: 1200px) {
  .comapany-h1 {
    font-size: 4rem;
  }
}
@media (max-width: 1500px) {
  .company-name {
    left: 35%;
  }
}
.hero-wrapper {
  position: relative;
  width: 100%;
  height: 50vh;
}

.hero-background {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* background-image: url("./assests/skybg.jpg"); */
  background-size: cover;
  background-position: center;
  /* clip-path: polygon(0 0, 100% 0, 100% 90%, 0 50%); */
}

.plane-container {
  position: relative;
}

.banner {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-color: #ffffff; /* Dark background for the banner */
  padding: 10px;
  text-align: center;
  z-index: 0; /* Place behind the plane */
}

.welcome-message {
  color: #fff; /* Text color */
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}
.slogan {
  color: #02b720; /* Text color */
  font-size: 1.5em; /* Slightly smaller than the welcome message */
  font-weight: bold;
  margin: 10px 0; /* Margin for spacing */
  line-height: 1.3;
}

.circle {
  position: absolute;
  top: 285px;
  right: 140px;
  width: 100px; /* Set the width of the circle */
  height: 100px; /* Set the height of the circle */
  background: transparent; /* Transparent center */
  border-radius: 50%; /* Make the div a perfect circle */
  border: 15px solid #c9b4d6;
}

.description {
  color: #000000; /* White text color for better contrast */
  font-size: 1em;
  margin: 10px 0; /* Margin for spacing */
  line-height: 1.5;
}
.card-icon {
  font-size: 2rem;
  color: #f5d061; /* Accent color */
  margin-bottom: 10px;
}
.plane-image {
  position: absolute;
  top: 20px;
  right: -120px; /* Start the plane off-screen */
  height: 120px;
  z-index: 1;
  animation: moveRightToLeft 5s linear infinite; /* Adjust duration as needed */
}

@keyframes moveRightToLeft {
  0% {
    right: -120px; /* Start off-screen on the right */
  }
  100% {
    right: 100vw; /* End off-screen on the left */
  }
}

.welcome-box {
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
  margin: 5px;
  z-index: 2;
  padding: 5px;
  background-color: transparent;
  width: 75rem;
  height: 30rem;
  display: flex;
  box-sizing: border-box;
}

.big-left {
  flex: 1;
  background-color: #fff; /* Example background color */
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px; /* Add padding around the agent image */
  min-height: 150px;
}

.agent-img {
  background-image: url("./assests/agent.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
  background-color: #fff; /* Ensure image container background is white */
}

.small-img {
  background-image: url("./assests/smallbg.png");
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
  min-height: 100px;
}
.card-title {
  font-size: 0.9rem;
  text-wrap: nowrap;
  color: #1e2a38 !important;
  font-weight: 700;
}
.card-description {
  font-size: 0.7rem;
  margin-top: 10px;
  font-weight: bold;
}
.right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.right-top,
.right-bottom {
  display: flex;
  flex: 1;
  justify-content: space-between;
  box-sizing: border-box;
}

.right-top {
  margin-bottom: 10px; /* Space between top and bottom sections */
}

.small-box {
  flex: 1;
  background-color: #fff; /* Example background color */
  padding: 10px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* To remove the margin for the first small box */
.right-top .small-box:first-child,
.right-bottom .small-box:first-child {
  margin-left: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
  .welcome-box {
    width: 90%;
    height: auto; /* Adjust height automatically */
    flex-direction: column;
  }

  .big-left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px; /* Space below the big box */
  }

  .right {
    width: 100%;
    flex-direction: column;
  }

  .right-top,
  .right-bottom {
    margin-bottom: 5px; /* Reduced space between sections on smaller screens */
  }

  .small-box {
    margin-left: 0;
    margin-bottom: 5px; /* Space between small boxes */
    width: calc(50% - 5px); /* Adjust width to fit smaller screens */
  }
  .mobile-hide {
    display: none;
  }
}

.overlay-content {
  position: relative;
  color: white;
  text-align: center;
  padding: 20px;
}

.contact-form {
  width: 400px;
  max-height: 450px;
  min-width: 20rem;
  background: #36454f;
  padding: 15px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 2; /* Ensure the form is above the background */
}

.contact-form-2 {
  width: 100%;
  height: 100%;
  min-width: 20rem;
  background: #36454f;
  padding: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.contact-form h2 {
  margin-bottom: 15px;
  font-size: 1.2em;
}

.form-group {
  margin-bottom: 6px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.form-group textarea {
  height: 70px;
  resize: none;
}

.overlay-content h2 {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 15px;
  text-shadow: 2px 2px 4px #36454f;
}

.overlay-content p {
  font-size: 1.2em;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 800px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  .plane-image {
    width: auto;
    height: 40px;
    top: 50px;
    right: 10px;
  }
  .contact-form {
    width: 100%;
    min-width: 20rem;
  }
  .hero-background {
    height: 61vh;
  }
  .hero-wrapper {
    height: 56vh;
  }

  .overlay-content h2 {
    font-size: 1.5em;
  }

  .overlay-content p {
    font-size: 1em;
    max-width: 90%;
  }
  .mobile-padding-x {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1000px) {
  .hero-wrapper {
    height: 55vh;
  }
}
/* styles.css */
.stylish-main-heading {
  font-family: "Playfair Display", serif;
  color: #1e2a38;
}

.stylish-subheading {
  font-family: "Roboto", sans-serif;
  color: #343f4b;
}

.darkbg {
  background-color: #edf3f5;
  width: 100%;
}

/* VisaCardComponent.css */
.visa-card-container {
  /* max-width: 100%; */
  /* border: 2px solid #1e2a38; Existing border style */
  border-radius: 0.5rem; /* Existing border radius */
  overflow: hidden; /* Ensure child elements respect border radius */
  padding: 0; /* Existing padding */
  background-color: #1e2a38;
}

.visa-image {
  width: 100%; /* Ensure image spans full width */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure proper layout */
}

.visa-content {
  padding: 2rem 2rem; /* Existing padding */
}

.country-name {
  font-family: "Playfair Display", serif;
  color: #fff;
  margin-bottom: 1rem;
  font-size: 2rem; /* Existing font size */
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
  gap: 10px;
}
.our-office {
  font-family: "Montserrat", sans-serif;

  color: #1e2a38;
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 1.25rem; /* Existing font size */
  display: flex; /* Align items horizontally */
  align-items: center; /* Center items vertically */
}
.flag-icon img {
  /* Make icon circular */
  /* Slightly lighter gray border */
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.7); Darker shadow for contrast */
  /* Ensure the icon stays circular */
  /* Darker gray background for the icon */
  height: 10px;
}

.flag-icon-2 {
  border-radius: 50%;
  border: 1px solid #343f4b;

  overflow: hidden;
  background-color: #333;
}
.visa-services {
  font-family: "Roboto", sans-serif;
  color: #f1f1f1;
}

.visa-services li {
  padding: 0.5rem 0; /* Padding for list items */
}
.carousel-indicators [data-bs-target] {
  background-color: #343f4b !important; /* Custom color for the indicators */
}

.carousel-indicators .active {
  background-color: #fd972b !important; /* Custom color for the active indicator */
}
/* src/components/AddressSection.css */
.address-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #edf3f5;
}
.request-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #edf3f5;
  min-height: 300px; /* Adjust this value as needed */
  padding-top: 20px; /* Optional: adjust top padding */
  padding-bottom: 20px; /* Optional: adjust bottom padding */
}
.flip-horizontal {
  width: 100%;
}
.address {
  font-family: "Roboto", sans-serif;
  color: #1e2a38;
}
.map-container {
  flex: 1;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.address-text {
  padding-top: 40px;
  flex: 1;
  max-width: 500px;
  padding-left: 1rem;
}

.hero-form {
  display: flex;
  justify-content: center;
  width: 80vw;
  gap: 20px;
  height: 70vh;
  background-color: #97e3f7;
  align-items: center;
}
.hero-box {
  display: flex;
  justify-content: center;
}
.box-text {
  font-family: "Lora", serif;
  color: white;
  font-size: 50px;
}
@media (max-width: 1368px) {
  .map-container {
    flex-direction: column !important;
  }
}
@media (max-width: 768px) {
  .address-section {
    flex-direction: column;
    align-items: start;
  }
  .country-image {
    width: 100% !important;
    margin-bottom: 1rem;
  }
  .mobile-flex-center {
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  .map-container {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }

  .map-container iframe {
    width: 100%;
    height: auto;
  }
  .address-text {
    padding-left: 0 !important;
    padding-top: 1rem;
  }
  .gmap {
    height: 300px !important;
  }
}

.submit-button {
  border: 2px solid #f1f1f1; /* Border color */
  background: #36454f; /* No background color */
  color: #fff; /* Text color */
  padding: 5px 12px; /* Padding around the text */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  overflow: hidden; /* Hide cutout overflow */
  border-radius: 4px; /* Rounded corners */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.submit-button:hover {
  background: #1e2a38; /* Button background color on hover */
  /* Text color on hover */
}

.submit-button:hover::before {
  background: #edf3f5; /* Background of cutout on hover */
}

.loader {
  width: 100%;
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0;
  position: relative;
}

.earth-wrapper {
  position: relative;
  width: 150px;
  height: 150px;
}

.earth {
  width: 100%;
  height: 100%;
  background: url(./assests//preloader.png) no-repeat center;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid #cdd1d3;
  animation: earthAnim 12s infinite linear;
  position: relative;
  z-index: 1;
}

@keyframes earthAnim {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.about-us {
  background-color: #f5f5f5;
  padding: 20px;
}

.hero-section {
  background-color: #36454f;
  color: #f5d061;
  padding: 50px 0;
}

.hero-section p {
  font-size: 1rem;
}

.mission-section {
  margin: 40px 0;
}

.team-section {
  margin: 40px 0;
}

.team-member {
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.team-member img {
  border-radius: 10px 10px 0 0;
}

.team-member .card-body {
  text-align: center;
  margin-bottom: 30px;
}
.country-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #edf3f5;
}

.country-image {
  width: 300px;
  height: 300px;
  margin-right: 20px;
}

.country-details {
  flex: 1;
}

.about-us {
  padding: 2rem;
  background-color: #f8f9fa;
}

.hero-section {
  margin-bottom: 3rem;
}

.mission-section h3 {
  margin-top: 2rem;
}

.contact-section {
  margin-top: 3rem;
}

.card {
  border-radius: 10px;
}

.card-body {
  padding: 2rem;
}

@media (max-width: 768px) {
  .hero-section p {
    font-size: 1.25rem;
  }
}
