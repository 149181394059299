.contact-section-box{

}
.contact-section-inbox{
    border-radius: 8px !important;
    background-color: #EFF5FF !important;
    padding: 3rem !important;
    align-items: center !important;
    height: 100% !important;
    display: flex !important;
}
.sent-button{
    background-color:#8cc63f ;
    color: white;
}

.shadow1{
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;

}
.shadow2{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    /* box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; */

}